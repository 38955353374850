import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const SecondSection = () => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Get valuable',
        es: 'Obtén'
    }    
    const title = {
        en: 'data to improve your business',
        es: 'información valiosa para mejorar tu negocio'
    }

    return (
        <ThreeColumns className='fluid'>
            <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                <StaticImage
                    src="../../../images/dashboard-2.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="dashboard-2"
                    className="img-container overflow-fluid-md"
                    imgClassName="overflowed-img"
                />
            </div>

            <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                <h2><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                <p>
                    {translateObject({
                        en: `Knowledge is power, through the qualifications your team receives 
                        and the dishes you will identify the points that your business has to improve, 
                        so they can provide better experiences to customers and guarantee their satisfaction.`,
                        es: `"El conocimiento es poder", a través de las calificaciones que recibe tu equipo
                        y los platillos identificarás lo tu negocio tiene que mejorar
                        para que puedas brindar mejores experiencias a los clientes y garantizar su satisfacción.`
                    })}
                </p>
            </div>

            <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                <StaticImage
                    src="../../../images/dashboard-3.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="dashboard-3"
                    className='img-container'
                    imgClassName='overflowed-img'
                />
            </div>
        </ThreeColumns>
    )
};

export default SecondSection;