import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { graphql, useStaticQuery } from "gatsby";
import '../globalStyles.css';
import { BG_DARK, BG } from '@etiquette-ui/colors';
import Description from "../sections/Products/Dashboard/description";
import SecondSection from "../sections/Products/Dashboard/second-section";

const Dashboard = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 2,
    title: 'Dashboard',
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroImage: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[3].node.base,
    heroMobile: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[3].node.base,
    banerImg: datainfo[4].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[4].node.base,
    banerImgMobile: datainfo[4].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[4].node.base,
    features: [
      {
        en: 'Get information from Dotell on staff performance and show it quickly',
        es: 'Obtén de forma rápida y eficiente información de Dotell sobre el rendimiento de tu equipo'
      },
      {
        en: 'Ten information segmented by areas / departments for a better analysis',
        es: 'Diez tipos de información segmentadas por áreas y departamentes para un mejor análisis'
      },
      {
        en: 'Resolves situations that have caused a customer to rate the service as deficient',
        es: 'Entérate de situaciones que fueron clasificadas como servicio deficientes según los clientes'
      },
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title={data.title} />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <SecondSection />
      </Section>
    </ProductLayout >
  );
};
export default Dashboard;

const query = graphql`
query dashboard {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "dashboard-hero",
          "dashboard-icon",
          "dashboard-banner",
          "digitalmenu-hero-mobile",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;