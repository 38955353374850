import React from 'react'
import Position from '../../../components/Position';
import Highlight from '../../../components/Highlight';
import {
    Row,
    Space,
    Center,
    Container,
    TwoColumns,
    InfoContainer,
    ContainerFluid,
    ImageContainer
} from '../../../GlobalStyles';
import { StaticImage } from "gatsby-plugin-image";

import useI18n from '../../../i18n/useI18n';

const Description = ({ location }) => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'What gets',
        es: 'Lo que se mide,'
    }    
    const title = {
        en: 'measured gets improved',
        es: 'se mejora'
    }
    
    return (
        <ContainerFluid>
            <Container>
                <Space />
                <Position location={location} />
                <Center>
                    <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                        <Highlight text={translateObject(highlightTitle)}/> {translateObject(title)}
                    </h2>
                    <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay=".2s">
                        {translateObject({
                            en: `You have already created your survey, you have already applied it to your clients, 
                            now it is the turn to view all that information clearly and easily through this tool.`,
                            es: `Ya creaste y aplicaste tus encuestas a tus clientes, ahora podrás ver la información a través de Dashboard.`
                        })}
                    </p>
                </Center>
            </Container>
            <TwoColumns>
                <Row>
                    <Container className='nested'>
                        <InfoContainer>
                            <div data-sal='slide-left' data-sal-easing="ease-in-circ">
                                <h3>
                                    {translateObject({
                                        en: `Check the results of your surveys`,
                                        es: `Revisa los resultados de tus encuestas`
                                    })}
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `You will see each of your clients responses to the questions you created 
                                        for your surveys displayed in the Dashboard, so you will know precisely how the performance 
                                        of each of the areas that were evaluated by your clients was.`,
                                        es: `En el Dashboard podrás ver cada una de las respuestas de tus clientes para las encuestas que creaste`
                                    })}
                                </p>
                            </div>

                            <div data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-delay='300'>
                                <h3>
                                    {translateObject({
                                        en: `Detect issues in any area`,
                                        es: `Detecta problemas`
                                    })}
                                </h3>
                                <p>
                                    {translateObject({
                                        en: `A perfect customer experience is a priority, there are times when it is not achieved 
                                        and when that happens you have to solve it. With the Dashboard, detecting these problems is easy, 
                                        so you can solve them as soon as possible.`,
                                        es: `La experiencia del cliente debería ser perfecta, a veces, no sucede y cuando esto pasa hay que resolverlo.
                                        Con Dashboard, será fácil detectar estos problemas para resolverlos lo más pronto posible.`
                                    })}
                                </p>
                            </div>
                        </InfoContainer>
                    </Container>
                </Row>
                <ImageContainer data-sal='slide-right' data-sal-easing="ease-in-circ" data-sal-duration='1000'>
                    <StaticImage
                        src="../../../images/dashboard-1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="dashboard-1"
                        className='mask-container'
                        imgClassName='img-fluid'
                    />
                </ImageContainer>
            </TwoColumns>
        </ContainerFluid>
    )
};

export default Description;